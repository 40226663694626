import React from 'react';
import {Alert, Snackbar} from "@mui/material";
import {useSnackBarContext} from "../context/SnackBarContext";

export const CustomSnackBar = () => {
    const {open, setOpen, message} = useSnackBarContext();

    function handleClose() {
        setOpen(false)
    }

    return (
        <Snackbar open={open} autoHideDuration={3000} onClose={handleClose} anchorOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}>
            <Alert
                onClose={handleClose}
                severity="success"
                variant="filled"
                sx={{width: '100%'}}
            >
                {message}
            </Alert>
        </Snackbar>
    )
}