import * as React from 'react';
import {useQuestionaryContext} from "../../../context/QuestionaryContext";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useMemo} from "react";

export const StepperController = () => {
    const {currentSection, setCurrentSection, numOfSections, answers, validateCurrentSection} = useQuestionaryContext();
    const isAllAnswered = useMemo(() => {
        return answers?.Section.slice(0, -1)?.every((section: any) => {
            return section.isValid
        });
    }, [answers]);

    function checkArrayAnswer(answer: any[]): boolean {
        const selected = answer.filter((a) => a.AnswerSelected);
        if(selected.length > 0 ){
            let valid = true;
            selected.forEach((a) => {
                if(a?.FreeFormContent===""){
                    valid = false;
                }
            });
            return valid;
        }
        return false
    }

    const isAnswered: boolean = useMemo(() => {
        return answers?.Section[currentSection]?.Question?.every((question: any) => {
            return Array.isArray(question.Answer) ?
                checkArrayAnswer(question.Answer)
                : (question.Answer?.option);
        });
    }, [answers, currentSection]);

    const handleNext = () => {
        validateCurrentSection();
        if (currentSection === numOfSections - 1) {
            if (!validateCurrentSection()) {
                return
            }
        }
        setCurrentSection(currentSection + 1);
        try {
            const stepper = document.getElementById('stepper_' + currentSection);
            stepper?.scrollIntoView({behavior: "smooth", block: "center", inline: "center"});
        } catch (e) {
            console.log(e)
        }
    };

    const handleBack = () => {
        setCurrentSection(currentSection - 1);
    };

    return (
        <Box sx={{width: '100%'}}>
            {currentSection === numOfSections ? (
                <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-around'}}>
                        <Button
                            sx={{mr: 1, borderRadius: 2}}
                            color="inherit"
                            variant="contained"
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Button
                            sx={{borderRadius: 2}}
                            onClick={handleNext}
                            variant="contained"
                            color="primary">
                            Confirm & Submit
                        </Button>
                    </Box>
                </React.Fragment>
            ) : (
                currentSection < numOfSections ? <React.Fragment>
                    <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-around'}}>
                        <Button
                            sx={{mr: 1, borderRadius: 2}}
                            color="inherit"
                            variant="contained"
                            disabled={currentSection == 0}
                            onClick={handleBack}
                        >
                            Back
                        </Button>
                        <Box gap={2} sx={{display: 'flex'}}>
                            <Button
                                disabled={currentSection === numOfSections - 1 ? !isAllAnswered : (answers?.Section[currentSection]?.Question?.length>0?!isAnswered: false)}
                                sx={{borderRadius: 2}}
                                onClick={handleNext}
                                variant="contained"
                                color="primary">
                                {currentSection === numOfSections - 1 ? 'Finish' : 'Next'}
                            </Button>
                            {currentSection < numOfSections - 1 && answers?.Section[currentSection]?.Question?.length>0?
                            <Button
                                sx={{borderRadius: 2}}
                                onClick={handleNext}
                                variant="contained"
                                color="success">
                                {'Skip'}
                            </Button>:
                                null}
                        </Box>
                    </Box>
                </React.Fragment> : null
            )}
        </Box>
    )
}