export const ExpandIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.701977">
                <path fillRule="evenodd" clipRule="evenodd"
                      d="M11.29 8.70998L6.69997 13.3C6.30997 13.69 6.30997 14.32 6.69997 14.71C7.08997 15.1 7.71997 15.1 8.10997 14.71L12 10.83L15.88 14.71C16.27 15.1 16.9 15.1 17.29 14.71C17.68 14.32 17.68 13.69 17.29 13.3L12.7 8.70998C12.32 8.31998 11.68 8.31998 11.29 8.70998Z"
                      fill="#0C77D8"/>
            </g>
        </svg>
    );
};