import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {Typography} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";

interface DialogProps {
    title: string;
    content: string;
    onConfirm: ()=>void;
    open: boolean;
    onClose: () => void;
}

export default function CustomConfirmDialog(props: DialogProps) {
    const {open, onClose} = props;
    function onClickConfirm() {
        props.onConfirm();
    }


    return (
        <React.Fragment>
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{sx: {borderRadius: "8px", p: 1, width: 340}}}
            >
                <DialogTitle id="alert-dialog-title" sx={{padding: '16px 16px 8px 16px'}}>
                    <Typography variant="h3" component='span'>{props.title}</Typography>
                </DialogTitle>
                <DialogContent sx={{paddingLeft: '16px', paddingRight: '16px', paddingBottom: '8px'}}>
                    <DialogContentText id="alert-dialog-description">
                        {props.content.split('\n').map((line: string, index: number)=>{
                            return(<Typography variant="subtitle1" key={line+index} sx={{color: '#000000'}} component='span'>{line}</Typography>)
                        })}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{justifyContent: 'end', pb: 1, gap: '8px', paddingBottom: '16px'}}>
                    <Button
                        sx={{textTransform: 'none', borderRadius: '8px', padding: '4px 12px 4px 12px', fontWeight: 700, fontSize: '14px', lineHeight: '24px'}}
                        onClick={onClose} variant="contained"
                            color="secondary">Cancel</Button>
                    <Button
                        sx={{textTransform: 'none', borderRadius: '8px', padding: '4px 12px 4px 12px', fontWeight: 700, fontSize: '14px', lineHeight: '24px'}}
                        onClick={onClickConfirm} autoFocus variant="contained"
                            color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
