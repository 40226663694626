import React from 'react';
import axiosHttp from "../../utils/axios";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import {Questionary} from "./Questionary";
import {Box, CircularProgress} from "@mui/material";
import {AllInOneQuestionaryView} from "./AllInOneQuestionaryView";

export const GetOne = () => {
    const {
        mpid,
        questionaryId,
        generateStartSessionInput,
        setResponseSessionId,
    } = React.useContext(QuestionaryContext);
    const [data, setData] = React.useState({} as any)
    const [loading, setLoading] = React.useState(true)
    React.useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant', {
                    "mpid": mpid,
                    "questionaryId": questionaryId,
                    "action": "GetOne",
                    "sourceSystem": "iClinic"
                })
                setData(response?.data?.data)

                try {
                    //start session
                    const response2 = await axiosHttp.post('/Questionary/Participant', generateStartSessionInput());
                    setResponseSessionId(response2?.data?.data?.ResponseSessionId)
                    setLoading(false);
                    return response;
                } catch (e) {
                    console.log(e)
                }

            } catch (error) {
                //handle error here...
                console.log(error)
            }

        }
        if(questionaryId){
            getData();
        }
    }, [questionaryId])

    return (loading ?
        <Box component="section"
             bgcolor={'#FFFFFF'}
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             justifyContent={'center'}
             height={'100%'}>
            <CircularProgress/>
        </Box>
        :
        <AllInOneQuestionaryView data={data}/>)
}