import React from 'react';
import {Box, Typography} from "@mui/material";
import {config} from "../../../config/config";

export const EmptyConfigScreen = () => {
    return (
        <Box p={'16px'} sx={{background: '#EDF6FF', borderRadius: '10px'}} mt={'16px'} mb={'16px'}>
            <Typography variant={'h5'}>Screening Configuration Assistance</Typography>
            <Typography variant={'h5'} sx={{color: '#434343', fontWeight: 400}}>No ICD Z-codes have been configured for this assessment. To start the process, please reach out to our customer support team at {config.csEmail} for assistance. </Typography>
        </Box>)
}