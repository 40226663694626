import React from 'react';
import './App.css';

import {
  createBrowserRouter, Navigate,
} from "react-router-dom";
import {GetOne} from "./scenes/questionary/getOne";
import {Home} from "./scenes/home/home";
import {MainLayout} from "./layout/mainLayout";
import {ViewAllUnfinish} from "./scenes/ViewAllUnfinish/ViewAllUnfinish";
import {ViewAllFinish} from "./scenes/report/ViewAllFinish";
import {ViewFinish} from "./scenes/report/ViewFinish";
import {MenuPage} from "./scenes/home/menu";
import {SsoView} from "./scenes/sso/SsoView";
import {SsoTest} from "./scenes/sso/SsoTest";
import {UserDetailView} from "./layout/UserDetail/UserDetailView";
import {NotFoundView} from "./scenes/notFound/NotFoundView"
import {ConfigHomePage} from "./scenes/config/configHomePage";
import {FindHelpHome} from "./scenes/findHelp/FindHelpHome";
export const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout><Home /></MainLayout>,

  },
  {
    path: "/home",
    element: <Navigate to="/" replace />,

  },
  {
    path: "/notFound",
    element: <NotFoundView/>,

  },
  {
    path: "/sso",
    element: <MainLayout><SsoView /></MainLayout>,

  },
  {
    path: "/sso/:mpid",
    element: <MainLayout><SsoView /></MainLayout>,

  },
  {
    path: "/ssoTest",
    element: <SsoTest />,

  },
  {
    path: "/menu",
    element: <MainLayout><MenuPage /></MainLayout>,

  },
  {
    path: "/unfinished",
    element: <MainLayout><ViewAllUnfinish /></MainLayout>,

  },
  {
    path: "/config",
    element: <MainLayout><ConfigHomePage /></MainLayout>,

  },
  {
    path: "/unfinished/getOne",
    element: <MainLayout><GetOne /></MainLayout>,
  },
  {
    path: '/finished',
    element: <MainLayout><ViewAllFinish /></MainLayout>,
  },
  {
    path: '/finished/:responseSessionId',
    element: <MainLayout><ViewFinish /></MainLayout>,
  },
  {
    path: '/iclinic/:mpid',
    element: <MainLayout><UserDetailView/></MainLayout>,
  },
  {
    path: '/findHelp',
    element: <MainLayout><FindHelpHome/></MainLayout>,
  },
]);

