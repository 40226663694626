import React from 'react';
import {FormControl, FormGroup, MenuItem, Select, TextField, Typography} from "@mui/material";
import {config} from '../../config/config'

interface Props {
    question: any,
    answers: any;
    getQuestionTranslation: (question: any, attribute: string, defaultValue?: string) => string
    getAnswerTranslation: (question: any, attribute: string, defaultValue?: string) => string;
    onChangeText: (e: any, index: number) => void
    selectAllForHardCode: any;
}

export const AddressHardCodeSection = (props: Props) => {
    const {getQuestionTranslation, question, getAnswerTranslation, onChangeText} = props;
    React.useEffect(() => {
        if(!props.answers.Answer[0].AnswerSelected){
            props.selectAllForHardCode();
        }
    }, [props.answers.Answer[0].AnswerSelected])
    return (
        <>
            <FormControl component="fieldset" variant="standard" fullWidth>
                <Typography id="checkBox-label" variant='h5'
                            mt={2}> {getQuestionTranslation(question, 'QuestionContent', question.QuestionName)}<span
                    className='red'>*</span></Typography>
                <FormGroup>
                    {/*street address*/}
                    <Typography>{getAnswerTranslation(question.Answer[0], 'AnswerTranslationContent', question.Answer[0]?.AnswerName)}</Typography>
                    <TextField
                        required
                        InputProps={{
                            style: {
                                borderRadius: "8px",
                            }
                        }}
                        size={'small'}
                        margin={'dense'}
                        placeholder={'e.g.123 Street, unit 903'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChangeText(event.target.value, 0);
                        }}
                        error={props.answers.Answer[0].AnswerSelected && !props.answers.Answer[0].text}
                        helperText={(props.answers.Answer[0].AnswerSelected && !props.answers.Answer[0].text) && 'required'}
                        value={props.answers.Answer[0].text ?? ''}/>
                </FormGroup>
            </FormControl>
            <FormControl component="fieldset" variant="standard">
                <FormGroup>
                    {/*city*/}
                    <Typography>{getAnswerTranslation(question.Answer[1], 'AnswerTranslationContent', question.Answer[1]?.AnswerName)}</Typography>
                    <TextField
                        InputProps={{
                            style: {
                                borderRadius: "8px",
                            }
                        }}
                        required
                        size={'small'}
                        margin={'dense'}
                        placeholder={'Please specify'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChangeText(event.target.value, 1);
                        }}
                        error={props.answers.Answer[1].AnswerSelected && !props.answers.Answer[1].text}
                        helperText={(props.answers.Answer[1].AnswerSelected && !props.answers.Answer[1].text) && 'required'}
                        value={props.answers.Answer[1].text ?? ''}/>
                    {/* State */}
                    <Typography>{getAnswerTranslation(question.Answer[2], 'AnswerTranslationContent', question.Answer[2]?.AnswerName)}</Typography>
                    <FormControl fullWidth size={'small'}>
                        <Select
                            sx={{borderRadius: '8px'}}
                            placeholder={'Please select'}
                            onChange={() => {
                            }}
                            value={props.answers.Answer[2].text ?? ''}>
                            {config.states.map((state: any) => {
                                return (<MenuItem
                                    key={state}
                                    value={state.value}
                                    onClick={() => {
                                        onChangeText(state.value, 2);
                                    }}>
                                    {state.text}
                                </MenuItem>)
                            })}
                        </Select>
                    </FormControl>
                    {/*Zip Code*/}
                    <Typography>{getAnswerTranslation(question.Answer[3], 'AnswerTranslationContent', question.Answer[3]?.AnswerName)}</Typography>
                    {props.answers.Answer[3].text.match(config.zipRegex)?null:<Typography color={'error'} className={'custom-error'}>Wrong format</Typography>}
                    <TextField
                        InputProps={{
                            style: {
                                borderRadius: "8px",
                            }
                        }}
                        inputProps={{
                            pattern: config.zipRegex,
                        }}
                        required
                        size={'small'}
                        margin={'dense'}
                        placeholder={'e.g. 12345'}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChangeText(event.target.value, 3);
                        }}
                        error={props.answers.Answer[3].AnswerSelected && !props.answers.Answer[3].text}
                        helperText={(props.answers.Answer[3].AnswerSelected && !props.answers.Answer[3].text) && 'required'}
                        value={props.answers.Answer[3].text ?? ''}/>

                </FormGroup>
            </FormControl>
        </>
    )
}