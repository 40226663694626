import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {router} from './App';
import reportWebVitals from './reportWebVitals';
import {RouterProvider} from "react-router-dom";
import {ThemeProvider} from '@mui/material/styles';
import {theme} from "./styles/theme";
import {QuestionaryProvider} from "./context/QuestionaryContext";
import {SnackBarProvider} from "./context/SnackBarContext";

//disable console.log
console.log = () => {}
console.error = () => {}
console.debug = () => {}

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <ThemeProvider theme={theme}>
            <SnackBarProvider>
            <QuestionaryProvider>
                <RouterProvider router={router}/>
            </QuestionaryProvider>
            </SnackBarProvider>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
