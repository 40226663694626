import React from 'react';
import {
    Link as RouterLink,
    useLocation, useNavigate,
} from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link, { LinkProps } from '@mui/material/Link';
import {Typography} from "@mui/material";
import {QuestionaryContext} from "../context/QuestionaryContext";

export const cleanRoute =[ '/','/menu', '/unfinished', '/finished']

const breadcrumbNameMap: { [key: string]: string } = {
    '/menu': 'Menu',
    '/unfinished/getOne': 'New',
    '/unfinished': 'UnFinished',
    '/finished//*': 'View',
    '/finished': 'Finished',
};


interface LinkRouterProps extends LinkProps {
    to: string;
    replace?: boolean;
}

function LinkRouter(props: LinkRouterProps) {
    return <Link {...props} component={RouterLink as any} />;
}
export const Breadcrumb = ()=>{
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const {mpid} = React.useContext(QuestionaryContext);
    const navigate = useNavigate();

    return (
        <Breadcrumbs aria-label="breadcrumb" sx={{pl:2, height: 27.5}}>
            <LinkRouter underline="hover" color="inherit" to="/">
                {pathnames?.length > 0 && pathnames[0]!=='sso' ? 'Home':''}
            </LinkRouter>
            {(pathnames?.length > 0 && mpid)?
                <LinkRouter underline="hover" color="inherit" to={'/menu'}>
                    {mpid}
                </LinkRouter>: null}
            {pathnames.filter(pathname=>pathname!=='sso').map((value, index) => {
                const last = index === pathnames.length - 1;
                const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                return last ? (
                    <Typography color="text.primary" key={to}>
                        {breadcrumbNameMap[Object.keys(breadcrumbNameMap).find((key: string)=>to.match(key))??'']}
                    </Typography>
                ) : (
                    <LinkRouter underline="hover" color="inherit" to={to} key={to}>
                        {breadcrumbNameMap[to]}
                    </LinkRouter>
                );
            })}
        </Breadcrumbs>
    );
}