import React from 'react';
import {IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

interface Props{
    mpid: string
}
export const IframeDialogView = (props: Props) => {
    let dialog = document.getElementById("dialog") as HTMLDialogElement;
    let src="https://d3qvc1yxk5dgmf.cloudfront.net/sso"
    React.useEffect(() => {
        dialog = document.getElementById("dialog") as HTMLDialogElement;
    }, [])
    return (<dialog id="dialog" style={{height: '774px', width: '969px'}}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <IconButton
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={() => {
                    dialog?.close();
                }}
                data-testid="drawerButton"
                sx={{mr: 2}}
            >
                <CloseIcon/>
            </IconButton></div>
        <div id="innerDialog" style={{height: '100%', width: '100%'}}>
            <iframe scrolling="no" id="sdoh_page" src={src} width="100%" height="100%"></iframe>
        </div>
    </dialog>)
}