import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Chip, Tooltip} from "@mui/material";
import Button from "@mui/material/Button";

interface Props {
    rows: any[];
    onClick?: (questionary: any) => void;
    handleBack: () => void;
}

var moment = require('moment');

export default function FinishTable(props: Props) {
    const getHrsnElement = (hrsns: any[]) => {
        if (hrsns.length > 0) {
            const title = hrsns.map((hrsn: any) => hrsn.sectionName).join(', ');
            return <Tooltip title={title}><Chip label={hrsns.length} color={'error'}/></Tooltip>
        } else {
            return <></>
        }

    }
    return props.rows?.length > 0 ? (
        <TableContainer component={Paper} className="table-wrapper">
            <Table aria-label="unfinish-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        {/*<TableCell>Code</TableCell>*/}
                        {/*<TableCell>Type</TableCell>*/}
                        <TableCell>Start Date</TableCell>
                        <TableCell>Completion Date</TableCell>
                        <TableCell>HRSN</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows?.length > 0 && props.rows.map((row) => (
                        <TableRow
                            hover
                            key={row.Questionary.QuestionaryId}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                            onClick={() => props.onClick && props.onClick(row)}
                        >
                            <TableCell component="th" scope="row">
                                {row.Questionary.QuestionaryId}
                            </TableCell>
                            <TableCell>{row.Questionary.QuestionaryName}</TableCell>
                            {/*<TableCell>{row.Questionary.QuestionaryCode}</TableCell>*/}
                            {/*<TableCell>{row.Questionary.QuestionaryType}</TableCell>*/}
                            <TableCell>{moment(row.CreateDateTime).format('MM/DD/YY')}</TableCell>
                            <TableCell>{moment(row.UpdateDateTime).format('MM/DD/YY')}</TableCell>
                            <TableCell>{getHrsnElement(row.HrsnSections ?? [])}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ):<Button
        sx={{borderRadius: 2}}
        onClick={props.handleBack}
        variant="contained"
        color="primary">
        {'Back to Menu'}
    </Button>
}