import React from 'react';
interface SnackBarContextType {
    open: boolean;
    setOpen: (open: boolean) => void;
    message: string;
    setMessage: (message: string) => void;
}

const SnackBarContext = React.createContext<SnackBarContextType>({} as SnackBarContextType);

interface Props {
    children: React.ReactNode;
}

const SnackBarProvider: React.FC<Props> = ({children}) => {
    const [open, setOpen] = React.useState(false);
const [message, setMessage] = React.useState("");
    return <SnackBarContext.Provider value={{
        open,
        setOpen,
        message,
        setMessage
    }}>{children}</SnackBarContext.Provider>;
};

const useSnackBarContext = () => {
    return React.useContext(SnackBarContext);
}

export {SnackBarContext, SnackBarProvider, useSnackBarContext};