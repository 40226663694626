import React from 'react';
import {Box, Divider} from "@mui/material";
import {PatientInfoDetailView} from "./PatientInfoDetailView";
import {SocialRiskSummaryView} from "./SocialRiskSummaryView";
import {ScreeningHistoryView} from "./ScreeningHistoryView";
import {BeyondHealthFooter} from "./BeyondHealthFooter";
import {QuestionaryContext} from "../../context/QuestionaryContext";

export const UserDetailView = () => {
    const {mpid} = React.useContext(QuestionaryContext);
    return mpid ? (
        <Box sx={{width: 400, bgcolor: '#FFFFFF', height: '100%', position: 'relative', borderRadius: '8px'}} padding='0'>
            {/*patientinfo*/}
            <PatientInfoDetailView/>
            <Divider/>
            {/*risk*/}
            <Box  sx={{overflowY: 'auto', maxHeight: 'calc( 100% - 135px)'}}>
                <SocialRiskSummaryView/>
                {/*History*/}
                <ScreeningHistoryView/>
            </Box>
        </Box>) : null
}