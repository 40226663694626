import React from 'react';
import {Box, Typography, Button, Divider} from "@mui/material";

export const ConfigPageHeader = ()=>{
    return (
        <Box>
            <Box height={40} padding='16px 15.5px 0 15.5px'>
                <Typography variant="h3">ICD Z-code Configuration</Typography>
            </Box>
            <Divider/>
        </Box>
    )
}
