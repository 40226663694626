import React from 'react';
import {AnswerInput, StartSessionInput} from "../interface/Types";
import {storage} from "../utils/storage";

interface QuestionaryContextType {
    mpid: string;
    setMpid: (mpid: string) => void;
    questionaryId: number;
    setQuestionaryId: (questionaryId: number) => void;
    numOfSections: number;
    setNumOfSections: (num: number) => void;
    sections: any[];
    setSections: (sections: any[]) => void;
    currentSection: number;
    setCurrentSection: (section: number) => void;
    answers: any;
    info: any;
    setInfo: (info: any) => void;
    initializeAnswerForm: (data: any, clear?: boolean) => void;
    setAnswer: (section: number, question: number, answer: any) => void;
    setAnswerText: (section: number, question: number, answer: any, text: string) => void;
    setQuestionaryPeriodId: (questionaryPeriodId: number) => void;
    generateStartSessionInput: () => StartSessionInput;
    setResponseSessionId: (responseSessionId: string) => void;
    setAnswerInput: () => AnswerInput;
    cleanSession: () => void;
    validateCurrentSection: () => boolean;
    lastUpdated: Date;
    setLastUpdated: (date: Date) => void;
    patientInfo: any;
    setPatientInfo: (patientInfo: any) => void;
    languages: any[];
    setLanguages: (languages: any[]) => void;
    languageId: number;
    setLanguageId: (id: number)=>void;
    histories: any[];
    setHistories: (histories: any[]) => void;
    copyFromLastScreening: (data: any, answers: any) => void;
    unfinished: any[];
    setUnfinished: (unfinished: any[]) => void;

}

const QuestionaryContext = React.createContext<QuestionaryContextType>(
    {} as QuestionaryContextType
);

interface Props {
    children: React.ReactNode;
}

const QuestionaryProvider: React.FC<Props> = ({children}) => {
    const [mpid, setMpid] = React.useState<string>("");
    const [questionaryId, setQuestionaryId] = React.useState<number>(0);
    const [numOfSections, setNumOfSections] = React.useState(0);
    const [sections, setSections] = React.useState<any[]>([]);
    const [currentSection, setCurrentSection] = React.useState(0);
    const [answers, setAnswers] = React.useState<any>(undefined);
    const [info, setInfo] = React.useState<any>({});
    const [questionaryPeriodId, setQuestionaryPeriodId] = React.useState<number>(-1);
    const [responseSessionId, setResponseSessionId] = React.useState<string>("");
    const [languageId, setLanguageId] = React.useState<number>(-1);
    const [languages, setLanguages] = React.useState<any[]>([]);
    const [histories, setHistories] = React.useState<any[]>([]);
    const [lastUpdated, setLastUpdated] = React.useState<Date>(new Date());
    const [patientInfo, setPatientInfo] = React.useState<any>({} as any);
    const [unfinished, setUnfinished] = React.useState<any[]>([]);

    React.useEffect(()=>{
        const localStorageMpid = storage.getItem("mpid", undefined);
        if(!mpid && localStorageMpid){
            setMpid(localStorageMpid)
        }
    },[])

    const cleanSession = () => {
        setQuestionaryId(0);
        setNumOfSections(0);
        setSections([]);
        setCurrentSection(0);
        setAnswers(undefined);
        setInfo({});
        setQuestionaryPeriodId(-1);
        setResponseSessionId("");
    }


    const generateStartSessionInput = () => {
        const temp: StartSessionInput = {
            mpid: mpid,
            questionaryPeriodId: questionaryPeriodId,
            action: "StartSession",
        }
        return temp;
    }

    const parseAnswer = () => {
        const temp: any[] = [];
        answers?.Section?.forEach((section: any) => {
            section.Question.forEach((question: any) => {
                if (Array.isArray(question.Answer)) {
                    (question.Answer.filter((a: any) => a.AnswerSelected).forEach((answer: any) => {
                        temp.push(answer.text ? {
                            "AnswerCode": answer.AnswerCode,
                            "IsValid": 1,
                            "FreeFormContent": answer.text
                        } : {
                            "AnswerCode": answer.AnswerCode,
                            "IsValid": 1,
                        })
                    }))
                } else {
                    temp.push(question.Answer.text ? {
                        "AnswerCode": question.Answer.option,
                        "IsValid": 1,
                        "FreeFormContent": question.Answer.text
                    } : {
                        "AnswerCode": question.Answer.option,
                        "IsValid": 1,
                    })
                }

            })
        })
        return temp;
    }
    const setAnswerInput = () => {
        const temp: AnswerInput = {
            mpid: mpid,
            responseSessionId: responseSessionId,
            languageId: languageId,
            responseAnswerBatch: parseAnswer(),
            action: "FinishSession",
        }
        return temp;
    }

    const setAnswer = (section: number, question: number, answer: any) => {
        const temp = {...answers};
        if (Array.isArray(temp.Section[section].Question[question].Answer)) {
            temp.Section[section].Question[question].Answer[answer].AnswerSelected = !temp.Section[section].Question[question].Answer[answer].AnswerSelected;
        } else {
            temp.Section[section].Question[question].Answer.option = answer;
        }
        setAnswers(temp);
    }
    const setAnswerText = (section: number, question: number, answer: any, text: string) => {
        const temp = {...answers};
        if (Array.isArray(temp.Section[section].Question[question].Answer)) {
            temp.Section[section].Question[question].Answer[answer].text = text;
        } else {
            temp.Section[section].Question[question].Answer.text = text;
        }
        setAnswers(temp);
    }

    function validateCurrentSection(): boolean {
        if (currentSection < numOfSections) {
            const currentSectionQuestion = sections[currentSection];
            const currentSectionAnswer = answers.Section[currentSection];
            let isValid = true;
            currentSectionAnswer.Question.forEach((question: any, index: number) => {
                if (Array.isArray(question.Answer)) {
                    if (question.Answer.filter((a: any) => a.AnswerSelected).length === 0) {
                        isValid = false;
                    } else {
                        question.Answer.filter((a: any) => a.AnswerSelected).forEach((answer: any) => {
                            const ori = currentSectionQuestion.Question[index].Answer.find((a: any) => a.AnswerCode === answer.AnswerCode);
                            if (ori?.IsFreeForm && !answer.text) {
                                isValid = false;
                            }
                        });
                    }
                } else {
                    const ori = currentSectionQuestion.Question[index].Answer.find((a: any) => a.AnswerCode === question.Answer.option);
                    if (!ori || (ori?.IsFreeForm && !question.Answer.text)) {
                        isValid = false;
                    }
                }
            });
            const temp = {...answers};
            temp.Section[currentSection].isValid = isValid;
            setAnswers(temp);
            return isValid;
        }
        return true;
    }

    const copyFromLastScreening = (data: any, answers: any)=>{
        if (data?.Section) {
            const temp = {
                "QuestionaryCode": data.QuestionaryCode,
                "Section": data.Section.map((section: any) => {
                    return {
                        "SectionName": section.SectionName,
                        "SectionCode": section.SectionCode,
                        "isValid": false,
                        "Question": section.Question.map((question: any) => {
                            return {
                                "QuestionName": question.QuestionName,
                                "QuestionCode": question.QuestionCode,
                                "Answer": question.QuestionAllowMultiAnswers ?
                                    question.Answer.map((a: any) => {
                                        let original = answers.find((answer: any) => a.AnswerCode === answer.AnswerCode);
                                        return {
                                            "AnswerCode": a.AnswerCode,
                                            "AnswerName": a.AnswerName,
                                            "AnswerSelected": original!==undefined,
                                            "text": original?.FreeFormContent??''
                                        }
                                    })
                                    : ({
                                            "option": question.Answer.find((a: any) => answers.find((answer: any) => a.AnswerCode === answer.AnswerCode))?.AnswerCode ?? undefined,
                                            "text": question.Answer.find((a: any) => answers.find((answer: any) => a.AnswerCode === answer.AnswerCode))?.FreeFormContent ?? ''
                                        })
                            }
                        })
                    }
                })
            }
            setAnswers(temp);
        }
    }

    const initializeAnswerForm = (data: any, clear?: boolean) => {
        if (data?.Section) {
            if(!languageId) {
                setLanguageId(data.QuestionaryTranslation[0]?.LanguageId ?? 1)
            }
            setNumOfSections(data.Section.length);
            setSections(data.Section);
            setInfo({
                "QuestionaryName": data.QuestionaryName,
                'QuestionaryCode': data.QuestionaryCode,
                "QuestionaryType": data.QuestionaryType,
            })
            const temp = {
                "QuestionaryCode": data.QuestionaryCode,
                "Section": data.Section.map((section: any) => {
                    return {
                        "SectionName": section.SectionName,
                        "SectionCode": section.SectionCode,
                        "isValid": false,
                        "Question": section.Question.map((question: any) => {
                            return {
                                "QuestionName": question.QuestionName,
                                "QuestionCode": question.QuestionCode,
                                "Answer": question.QuestionAllowMultiAnswers ?
                                    question.Answer.map((a: any) => {
                                        return {
                                            "AnswerCode": a.AnswerCode,
                                            "AnswerName": a.AnswerName,
                                            "AnswerSelected": (!!a?.PrepopulateFieldValue && !clear),
                                            "text": (a?.PrepopulateFieldValue && a.PrepopulateFieldValue !== "1"  && !clear) ? a?.PrepopulateFieldValue : ''
                                        }
                                    })
                                    : !clear?{
                                        "option": question.Answer.find((a: any) => a.PrepopulateFieldValue === "1")?.AnswerCode ?? undefined,
                                        "text": question.Answer.find((a: any) => a.PrepopulateFieldValue && a.PrepopulateFieldValue !== "1")?.PrepopulateFieldValue ?? ''
                                    }:
                                        {
                                            "option": undefined,
                                            "text": ''
                                        }
                            }
                        })
                    }
                })
            }
            setAnswers(temp);
        }
    }

    return (
        <QuestionaryContext.Provider
            value={{
                mpid,
                setMpid,
                questionaryId,
                setQuestionaryId,
                numOfSections,
                setNumOfSections,
                sections,
                setSections,
                currentSection,
                setCurrentSection,
                answers,
                info,
                setInfo,
                initializeAnswerForm,
                setAnswer,
                setAnswerText,
                setQuestionaryPeriodId,
                generateStartSessionInput,
                setResponseSessionId,
                setAnswerInput,
                cleanSession,
                validateCurrentSection,
                lastUpdated,
                setLastUpdated,
                patientInfo,
                setPatientInfo,
                languages,
                setLanguages,
                languageId,
                setLanguageId,
                histories,
                setHistories,
                copyFromLastScreening,
                unfinished,
                setUnfinished
            }}>
            {children}
        </QuestionaryContext.Provider>
    );
};

const useQuestionaryContext = () => {
    return React.useContext(QuestionaryContext);
}

export {QuestionaryContext, QuestionaryProvider, useQuestionaryContext};