import axiosHttp from "./axios";
import {decryptData, encryptData} from "./encryptData";

export const storage = {
    getItem(key: string, fallbackValue: string | undefined) {
        try {
            return decryptData(sessionStorage.getItem(key)) ??'';
        } catch(e) {
            return fallbackValue;
        }
    },
    setItem(key: string, value: string) {
        try {
            sessionStorage.setItem(key, encryptData(value));
        } catch(e) {
            console.log(e)
        }
    },
    removeItem(key: string,) {
        try {
            sessionStorage.removeItem(key);
        } catch(e) {
            console.log(e)
        }
    },
    getFrontendToken: async(mpid: string)=>{
        try{
            //start session
            const response = await axiosHttp.post('/Auth/GetFrontendTokenForMpidDev', {
                "mpid": mpid
            });
            const token = (response?.data?.data?.AccessToken)
            storage.clearAll();
            storage.setItem("AccessToken", token);
            storage.setItem('mpid', mpid);
            return response;
        }
        catch(e){
            console.log(e)
        }
    },
    clearAll: ()=>{
        try{
            sessionStorage.clear();
        }
        catch(e){
            console.log(e)
        }
    }
}