import * as React from 'react';
import {Box, Typography} from "@mui/material";
import {NotExpandedIcon} from "../images/Icons/NotExpandedIcon"
import {ExpandIcon} from "../images/Icons/ExpandIcon";

interface Props{
    open: boolean,
    onClick: ()=>void,
    title: string,
    children: any
}
export const CustomAccordion = (props: Props)=>{
    return (<Box sx={{borderRadius: '8px', backgroundColor:'#EDF6FF'}} className={props.open?'customAccordion expanded':'customAccordion'}>
        <Box className='hoverItem' onClick={()=>props.onClick()} sx={{display: 'flex', height: '40px', alignItems: 'center', paddingLeft: '8px'}}>
            {props.open?<ExpandIcon/>:<NotExpandedIcon/>}
            <Typography variant={'h4'} sx={{color: '#0C77D8', paddingLeft: '8px'}}>{props.title}</Typography></Box>
        {props.open?<Box sx={{paddingLeft: '39px', paddingBottom: '8px'}}>{props.children}</Box>:null}
    </Box>)
}