import React from 'react';
import {Typography, Box} from "@mui/material";

export const NotFoundView = ()=>{
    return (
        <Box component="section"
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             justifyContent={'center'}
             height={'100vh'}>
            <Typography>Session expired, Please Login Again</Typography>
        </Box>
    )
}