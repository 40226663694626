import React from 'react';
import {
    Box, Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Radio,
    RadioGroup,
    TextField,
    Typography
} from "@mui/material";
import HorizontalLinearStepper from "./components/Stepper";
import {useQuestionaryContext} from "../../context/QuestionaryContext";
import {StepperController} from "./components/StepperController";
import {PreviewResult} from "./components/PreviewResult";
import {SubmitPage} from "./components/SubmitPage";
import {checkArrayAnswer} from "./AllInOneQuestionaryView";
import {AddressHardCodeSection} from "./AddressHardCodeSection";

interface QuestionSectionProps {
    question: any,
    setAnswer: any,
    setAnswerText?: any,
    currentSection: number
    currentQuestion: number
    answers: any;
    checked?: boolean;
    hardCode?: any;
}

export const QuestionSection = (props: QuestionSectionProps) => {
    const {question} = props;
    const {languageId} = useQuestionaryContext();

    const onChangeText = (e: any, index: number) => {
        props.setAnswerText(props.currentSection, props.currentQuestion, index, e)
    }
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, answer: any, index: number) => {
        props.setAnswer(props.currentSection, props.currentQuestion, index)
    };

    const selectAllForHardCode = () => {
        props.setAnswer(props.currentSection, props.currentQuestion, 0);
        props.setAnswer(props.currentSection, props.currentQuestion, 1);
        props.setAnswer(props.currentSection, props.currentQuestion, 2);
        props.setAnswer(props.currentSection, props.currentQuestion, 3);
    }

    const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const v = (event.target as HTMLInputElement).value
        if (v) {
            props.setAnswer(props.currentSection, props.currentQuestion, v)
        }
    }

    function getQuestionTranslation(question: any, attribute: string, defaultValue?: string) {
        try {
            let result = question?.QuestionTranslation?.find((tran: any) => tran.LanguageId === languageId)?.[`${attribute}`]
            return result || defaultValue
        } catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    function getAnswerTranslation(answer: any, attribute: string, defaultValue?: string) {
        try {
            let result = answer?.AnswerTranslation?.find((tran: any) => tran.LanguageId === languageId)?.[`${attribute}`]
            return result || defaultValue
        } catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    function disabledByAvailableAnswers(question: any, selected: any[], currentAnswerCode: string): boolean {
        if (question?.AnswerCombinationListJson) {
            const answerCombinationList = JSON.parse(question?.AnswerCombinationListJson);
            const currentSelected = selected.filter((s: any) => s.AnswerSelected).map((a: any) => a.AnswerCode)
                if (currentSelected.length > 0) {
                    if(answerCombinationList.find((c: any)=>c.includes('*^'))){
                        const notSelected = answerCombinationList.find((c: any)=>c.includes('*^')).filter((comb: any) => comb!=='*^')[0];
                        console.log('notSelected',notSelected)
                        console.log(currentSelected)
                        if(currentSelected.includes(notSelected)){
                            return currentAnswerCode!==notSelected;
                        }
                        return currentAnswerCode===notSelected;
                    }
                    //do sth
                    else {
                        currentSelected.push(currentAnswerCode)
                        const availableCombinations = answerCombinationList.filter((comb: any) => currentSelected.every((v: any) => comb.includes(v)));
                        return availableCombinations.length < 1;
                    }
                }

        }
        return false;
    }

    if (props.hardCode && question?.QuestionName === props.hardCode?.questionName) {
        return (
            <AddressHardCodeSection
                question={question}
                answers={props.answers}
                getQuestionTranslation={getQuestionTranslation}
                getAnswerTranslation={getAnswerTranslation}
                onChangeText={onChangeText}
                selectAllForHardCode={selectAllForHardCode}
            />)
    }

    return question.QuestionAllowMultiAnswers ?
        (<FormControl component="fieldset" variant="standard">
            <Typography id="checkBox-label" variant='h5'
                        mt={1.5} mb={1}> {getQuestionTranslation(question, 'QuestionContent', question.QuestionName)}<span
                className='red'>*</span></Typography>
            {!checkArrayAnswer(props.answers.Answer) && props.checked ?
                <Typography color='error' className={'custom-error'}>Please select an option.</Typography> : null}
            <FormGroup>
                {question.Answer.map((answer: any, index: number) => {
                    return (
                        <div key={answer?.AnswerId + index + 'multi'} className={'flex'}
                             style={{minHeight: '24px',paddingBottom: index!==question.Answer.length-1?'8px':0}}>
                            <FormControlLabel
                                key={answer?.AnswerId}
                                control={
                                    <Checkbox
                                        style={{
                                            transform: "scale(0.87)",
                                        }}
                                        size={'small'}
                                        sx={{padding: '0px 9px 0px 9px'}}
                                        disabled={disabledByAvailableAnswers(question, props.answers.Answer, answer.AnswerCode)}
                                        checked={props.answers.Answer[index].AnswerSelected}
                                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleChange(event, answer, index)}
                                        name={answer?.AnswerCode ?? ''}/>
                                }
                                label={<div className={'answerText'}>{getAnswerTranslation(answer, 'AnswerTranslationContent', answer?.AnswerName)}</div>}/>
                            {(answer?.IsFreeForm) ?
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: "8px",
                                        }
                                    }}
                                    required
                                    size={'small'}
                                    margin={'dense'}
                                    placeholder={'Please specify'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onChangeText(event.target.value, index);
                                    }}
                                    error={props.answers.Answer[index].AnswerSelected && !props.answers.Answer[index].text}
                                    helperText={(props.answers.Answer[index].AnswerSelected && !props.answers.Answer[index].text) && 'required'}
                                    value={props.answers.Answer[index].text ?? ''}/> : null}
                        </div>
                    )
                })}
            </FormGroup>
        </FormControl>)
        :
        (<FormControl required>
            <Typography id="checkBox-label"
                        variant='h5'  mt={1.5} mb={1}> {getQuestionTranslation(question, 'QuestionContent', question.QuestionName)}<span
                className='red'>*</span></Typography>
            {!props.answers.Answer?.option && props.checked ?
                <Typography color='error' className={'custom-error'}>Please select an option.</Typography> : null}
            <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                value={props.answers.Answer?.option ?? ''}
                name="radio-buttons-group"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleRadioChange(event)}
            >
                {question.Answer.map((answer: any, index: number) => {
                    return (
                        <div key={answer?.AnswerId + index + 'single'} className={'flex'}
                             style={{minHeight: '24px',paddingBottom: index!==question.Answer.length-1?'8px':0}}>
                            <FormControlLabel
                                key={answer?.AnswerId}
                                value={answer?.AnswerCode}
                                control={<Radio
                                    style={{
                                        transform: "scale(0.87)",
                                    }}
                                    size={'small'} sx={{padding: '0px 9px 0px 9px'}}/>}
                                label={<div className={'answerText'}>{getAnswerTranslation(answer, 'AnswerTranslationContent', answer?.AnswerName)}</div>} />
                            {(answer?.IsFreeForm) ?
                                <TextField
                                    InputProps={{
                                        style: {
                                            borderRadius: "8px",
                                        }
                                    }}
                                    required
                                    margin={'dense'}
                                    size={'small'}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        onChangeText(event.target.value, index);
                                    }}
                                    error={(props.answers.Answer?.option === answer.AnswerCode && !props.answers.Answer?.text)}
                                    helperText={(props.answers.Answer?.option === answer.AnswerCode && !props.answers.Answer?.text) && 'required'}
                                    value={props.answers.Answer?.text ?? ''}/> : null}
                        </div>
                    )
                })}
            </RadioGroup>
        </FormControl>)
}

interface Props {
    data: any
}

export const Questionary = (props: Props) => {
    const {
        numOfSections,
        currentSection,
        initializeAnswerForm,
        sections,
        setAnswer,
        answers,
        setAnswerText
    } = useQuestionaryContext();
    const [data, setData] = React.useState(props.data)

    React.useEffect(() => {
        if (data?.Section) {
            initializeAnswerForm(data)
        }
    }, [data?.Section])

    return <Box
        display="flex"
        alignItems="center"
        flexDirection={'column'}
        justifyContent={'center'}
        p={2}>
        <HorizontalLinearStepper/>
        <Box sx={{display: 'flex', width: '100%', flexDirection: 'column'}}>
            {currentSection === numOfSections ? (
                <PreviewResult/>
            ) : currentSection > numOfSections ? <SubmitPage/> : null}
            {sections?.length > 0 && sections[currentSection] ?
                <Box key={sections[currentSection].SectionId + sections[currentSection].SectionName}>
                    <Typography mt={2} mb={2} variant="h6">{sections[currentSection].SectionName}</Typography>
                    <Typography mt={2} mb={2} color="text.secondary" variant="body2">
                        {sections[currentSection].SectionDescription}
                    </Typography>
                    {sections[currentSection]?.Question.length > 0 && sections[currentSection]?.Question?.map((question: any, index: number) => {
                        return (<FormGroup row key={question.QuestionId + sections[currentSection].SectionName}>
                            <QuestionSection
                                question={question}
                                setAnswer={setAnswer}
                                setAnswerText={setAnswerText}
                                currentSection={currentSection}
                                currentQuestion={index}
                                answers={answers.Section[currentSection].Question[index]}
                            />
                        </FormGroup>)
                    })}
                </Box>
                :
                null
            }
        </Box>
        <StepperController/>
    </Box>
}