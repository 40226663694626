import React from 'react';
import {Checkbox, FormControl, FormControlLabel, FormGroup, TextField, Typography} from "@mui/material";
import {IframeDialogView} from "./iframe";

export const SsoTest = () => {
    const domain = 'https://d3qvc1yxk5dgmf.cloudfront.net';
    const path = '/sso';
    const targetUrl = domain + path;
    const [token, setToken] = React.useState('');
    const [targetUser, setTargetUser] = React.useState('');
    const [source, setSource] = React.useState('');
    const [viewOnly, setViewOnly] = React.useState(false);
    let src="https://d3qvc1yxk5dgmf.cloudfront.net/sso"+ (targetUser?`/${targetUser}`:'')
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <IframeDialogView mpid={targetUser ?? ''}/>
            <TextField
                placeholder="Token"
                onChange={(event: any) => {
                    setToken(event.target.value ?? '');
                }}
            />
            <TextField
                placeholder="mpid"
                onChange={(event: any) => {
                    setTargetUser(event.target.value ?? '');
                }}
            />
            <TextField
                placeholder="source"
                onChange={(event: any) => {
                    setSource(event.target.value ?? '');
                }}
            />
            <FormControl component="fieldset" variant="standard">
                <Typography  variant='h5'>Permission</Typography>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                size={'small'}
                                sx={{padding: '7px 9px 7px 9px'}}
                                checked={viewOnly}
                                onChange={()=>{setViewOnly(!viewOnly)}}
                                name={'View Only'}/>
                        }
                        label={'View Only'}/>
                </FormGroup>
            </FormControl>
            <button
                disabled={!token || !targetUser}
                onClick={() => {
                    let popUp = window.open(targetUrl, '');
                    const message = {
                        token: token,
                        mpid: targetUser,
                        source: source,
                        permission: viewOnly?'ViewOnly':'All'
                    };
                    const json = JSON.stringify(message);
                    setTimeout(() => {
                        popUp?.postMessage(json, targetUrl);
                    }, 2000);
                }}
            >
                Send
            </button>
            <button
                disabled={!token || !targetUser}
                onClick={() => {
                    let sdoh = (document.getElementById('sdoh_page') as HTMLIFrameElement)
                    let dialog = document.getElementById("dialog") as HTMLDialogElement;
                    if(sdoh && dialog) {
                        sdoh.src= sdoh.src+''
                        const message = {
                            token: token,
                            mpid: targetUser,
                            source: source,
                            permission: viewOnly?'ViewOnly':'All'
                        };
                        const json = JSON.stringify(message);
                        setTimeout(() => {
                            sdoh.contentWindow?.postMessage(json, targetUrl);
                        },1000)

                        dialog?.showModal();
                    }
                }}
            >
                open Iframe
            </button>
        </div>
    );
}