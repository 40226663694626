import * as React from 'react';
import {Box, LinearProgress, Typography} from "@mui/material";
import {useQuestionaryContext} from "../../../context/QuestionaryContext";
import axiosHttp from "../../../utils/axios";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {config} from "../../../config/config";

export const SubmitPage = () => {
    const [loading, setLoading] = React.useState(true)
    const {setAnswerInput, setLastUpdated, patientInfo} = useQuestionaryContext();
    const navigate = useNavigate();
    const flag = React.useRef(false);
    React.useEffect(() => {
        const submitForm = async () => {
            try {
                let data = setAnswerInput();
                // set clinicId for icd
                data.clinicId = parseInt(patientInfo.clinicId??config.defaultClinicId);
                let response: any =undefined;
                if(!flag.current) {
                    await axiosHttp.post('/Questionary/Participant',
                        data).then((res) => {
                        response = res;
                        flag.current = true;
                    }).catch((err) => {
                        console.log(err)
                    });
                }
                if(response?.data?.isSuccess === false){
                    console.log(response?.data?.failReason);
                }
                setLoading(false);
                // setTimeout(()=>{
                    setLastUpdated(new Date());
                    navigate('/finished/'+ response?.data?.data?.ResponseSessionId);
                // }, 3000);

            } catch (error) {
                //handle error here...
                console.log(error)
            }
        }
        if(loading){
            submitForm();
        }
    }, [])
    return (
        <Box sx={{display: 'flex', width: '100%', flexDirection: 'column'}} pt={2}>
            <Typography variant="h4" sx={{width: 'fit-content', margin: 'auto'}}>{loading ? 'Saving' : 'Redirecting...'}</Typography>
            {loading ? <Box sx={{width: '100%'}}>
                    <LinearProgress/>
                </Box>
                :
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'space-around', width: '100%'}}>
                    {/*<Button onClick={handleOnClick} autoFocus variant="contained"*/}
                    {/*        color="primary">*/}
                    {/*    Create*/}
                    {/*</Button>*/}
                    {/*<Button onClick={handleOnClickView} autoFocus variant="contained"*/}
                    {/*        color="primary">*/}
                    {/*    View*/}
                    {/*</Button>*/}
                </Box>
            }
        </Box>)
}