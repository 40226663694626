import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from "@mui/material/Button";

interface Props{
    rows: any[];
    onClick?: (questionary: any) => void;
    handleBack: () => void;
}
var moment = require('moment');
export default function UnFinishTable(props: Props) {
    return props.rows?.length > 0 ? (
        <TableContainer component={Paper} className="table-wrapper" data-testid={'unFinishTable'}>
            <Table aria-label="unfinish-table">
                <TableHead>
                    <TableRow>
                        <TableCell>Id</TableCell>
                        <TableCell>Name</TableCell>
                        {/*<TableCell>Code</TableCell>*/}
                        {/*<TableCell>Type</TableCell>*/}
                        <TableCell>Start Time</TableCell>
                        <TableCell>End Time</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.rows?.length>0 && props.rows.map((row) => (
                        <TableRow
                            hover
                            key={row.Questionary.QuestionaryId}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            onClick={()=>props.onClick && props.onClick(row)}
                        >
                            <TableCell component="th" scope="row">
                                {row.Questionary.QuestionaryId}
                            </TableCell>
                            <TableCell>{row.Questionary.QuestionaryName}</TableCell>
                            {/*<TableCell>{row.Questionary.QuestionaryCode}</TableCell>*/}
                            {/*<TableCell>{row.Questionary.QuestionaryType}</TableCell>*/}
                            <TableCell>{moment(row.StartDateTime).format('MM/DD/YY')}</TableCell>
                            <TableCell>{moment(row.EndDateTime).format('MM/DD/YY')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    ):<Button
        sx={{borderRadius: 2}}
        onClick={props.handleBack}
        variant="contained"
        color="primary">
        {'Back to Menu'}
    </Button>;
}
