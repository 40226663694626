import React from 'react';
import {Box, Button, CircularProgress, TextField, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import {QuestionaryContext} from "../../context/QuestionaryContext";
import {storage} from "../../utils/storage";

export const Home = () => {
    const [mpidInput, setMpidInput] = React.useState<string>("")
    const {setMpid, mpid} = React.useContext(QuestionaryContext);
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false)

    React.useEffect(()=>{
        if(mpid){
            navigate('/menu');
        }
        else{
            navigate('/notFound')
        }
    },[mpid])
    const handleOnClick = async() => {
        const response = await storage.getFrontendToken(mpidInput);
        if(response?.data?.data?.AccessToken){
            setMpid(mpidInput);
            storage.setItem('permission', 'All');
            navigate('/menu');
            setLoading(false);
        }
        else{
            setLoading(false);
            alert("Invalid mpid")
        }
    }
    return loading?<CircularProgress/>:(
        <Box component="section"
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             justifyContent={'center'}
            height={'100%'}>
            <Typography>Please enter mpid</Typography>
            <TextField id="mpid" label="mpid" variant="outlined" margin="normal"
                       value={mpidInput}
                       onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                           setMpidInput(event.target.value);
                       }}
                       required/>
            <Button
                disabled={!mpidInput}
                sx={{mt:2}}
                variant="contained"
                color="primary"
                onClick={()=>handleOnClick()}>Start</Button>
        </Box>
    )
}