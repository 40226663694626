import React, {useMemo} from 'react';
import {useQuestionaryContext} from "../../context/QuestionaryContext";
import {
    Box,
    Button,
    LinearProgress,
    FormGroup,
    Typography,
    FormControl,
    Select,
    MenuItem,
    OutlinedInput
} from "@mui/material";
import {QuestionSection} from "./Questionary";
import {QuestionHeader} from "./components/QuestionHeader/QuestionHeader";
import {SubmitPage} from "./components/SubmitPage";
import CloseIcon from "@mui/icons-material/Close";
import {useNavigate} from "react-router-dom";
import CustomConfirmDialog from "./components/QuestionHeader/CustomConfirmDialog";
import {config} from "../../config/config";

interface Props {
    data: any
}

export function checkArrayAnswer(answer: any[]): boolean {
    const selected = answer.filter((a) => a.AnswerSelected);
    if (selected.length > 0) {
        let valid = true;
        selected.forEach((a) => {
            if (a?.FreeFormContent === "") {
                valid = false;
            }
        });
        return valid;
    }
    return false
}

export const AllInOneQuestionaryView = (props: Props) => {
    const {questionaryId, initializeAnswerForm, sections, setAnswer, answers, setAnswerText, languageId, languages, setLanguageId} = useQuestionaryContext();
    const [data, setData] = React.useState(props.data)
    const [loading, setLoading] = React.useState(false);
    const [clickedCheck, setClickedCheck] = React.useState(false);
    const [onSubmit, setOnSubmit] = React.useState(false);
    const [onClickClose, setOnClickClose] = React.useState(false);
    const navigate = useNavigate();

    function getSectionTranslation(section: any, attribute: string, defaultValue?: string){
        try{
            let result = section?.SectionTranslation?.find((tran: any)=>tran.LanguageId===languageId)?.[`${attribute}`]
            return result || defaultValue
        }
        catch (e) {
            console.log(e);
            return defaultValue
        }
    }

    React.useEffect(() => {
        if (data?.Section) {
            initializeAnswerForm(data)
        }
    }, [data?.Section])

    const isAnswered: boolean = useMemo(() => {
        return answers?.Section?.every((section: any) => {
            return section.Question?.every((question: any) => {
                return Array.isArray(question.Answer) ?
                    checkArrayAnswer(question.Answer)
                    : (question.Answer?.option);
            })
        });
    }, [answers]);

    const scrollIntoError=()=>{
        let elements = document.getElementsByClassName('custom-error')
        if(elements && elements.length>0){
            elements[0].scrollIntoView({behavior: "smooth",block: "center", inline: "nearest"});
        }
    }

    const handleCheck = ()=>{
        if(!clickedCheck){
            setClickedCheck(true);
        }
        else{
            scrollIntoError();
        }
    }
    React.useEffect(()=>{
        if(clickedCheck){
            scrollIntoError();
        }
    },[clickedCheck]);

    return <Box
        display="flex"
        flexDirection={'column'}
        position={'relative'}
        bgcolor={'#FFFFFF'}
        justifyContent={'center'}
        sx={{borderRadius: '8px', maxHeight: '100%'}}>
        <QuestionHeader data={props.data} loading={loading} setLoading={setLoading} setClickedCheck={setClickedCheck}/>
        <CustomConfirmDialog
            title={'Close Screening'}
            content={'Are you sure you want to close without saving?\nAny unsaved changes will be lost.'}
            open={onClickClose}
            onClose={() => setOnClickClose(false)}
            onConfirm={() => {navigate('/menu')}}
        />
        {loading ? <LinearProgress sx={{margin: 2}}/>
            : onSubmit ? <SubmitPage/> : (
                <Box sx={{display: 'flex', flexDirection: 'column', overflowY: 'auto'}} padding='16px 15.5px 0 15.5px'>
                    <CloseIcon className='closeButton' onClick={()=>{setOnClickClose(!onClickClose)}}/>
                    <Box display={'flex'} paddingTop={'8px'} paddingBottom={'8px'}>
                        <FormControl fullWidth size={'small'} >
                            <Typography variant='h5'>Language</Typography>
                            <Select
                                id="language-select"
                                value={languageId}
                                input={<OutlinedInput sx={{fontSize: '14px', fontWeight: 400}} />}
                            >
                                {languages.filter((l:any)=>data.QuestionaryTranslation.find((d: any)=>d.LanguageId===l.LanguageId)).map((language: any)=>{
                                    return  <MenuItem
                                        key={language.LanguageId}
                                        value={language.LanguageId}
                                        onClick={()=>{setLanguageId(language.LanguageId)}}
                                    >{language.DisplayText}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Box>
                    {sections?.length > 0 && sections.map((section, sectionIndex) =>
                        <Box key={section.SectionId + section.SectionName}>
                            <Typography mt={2} variant="h3">{getSectionTranslation(section, 'SectionContent', section.SectionName)}</Typography>
                            {section.SectionDescription?<Typography mt={2} variant="body2">
                                {getSectionTranslation(section, 'SectionDescription', section.SectionDescription)}
                            </Typography>:null}
                            {section?.Question.length > 0 && section?.Question?.map((question: any, index: number) => {
                                return (<FormGroup row key={question.QuestionId + section.SectionName}>
                                    <QuestionSection
                                        checked={clickedCheck}
                                        question={question}
                                        setAnswer={setAnswer}
                                        setAnswerText={setAnswerText}
                                        currentSection={sectionIndex}
                                        currentQuestion={index}
                                        answers={answers.Section[sectionIndex].Question[index]}
                                        hardCode={config.addressHardCodeSets.find((set: any)=>set.id===questionaryId)}
                                    />
                                </FormGroup>)
                            })}
                        </Box>)}
                </Box>)}
        <Box padding='16px 15.5px 24px 15.5px' display={'flex'} justifyContent={'flex-end'} mr={3}>
            {!onSubmit && <Button
                sx={{textTransform: 'none', borderRadius: '8px'}}
                variant="contained"
                onClick={() => {
                    isAnswered? setOnSubmit(true): handleCheck()
                }}>{isAnswered?'Submit':'Check'}</Button>
            }
        </Box>
    </Box>
}