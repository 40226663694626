import React from 'react';
import {Box, Typography, Divider, Button, Alert} from "@mui/material";
import {QuestionaryContext} from "../../../context/QuestionaryContext";
import {CustomAccordion} from "../../../styledComponents/CustomAccordation";
import axiosHttp from "../../../utils/axios";
import {storage} from "../../../utils/storage";
import DoneIcon from "@mui/icons-material/Done";

interface Props {
    data: any;
    responseSessionId: string;
}

var moment = require('moment');
export const HistoryQuestionHeader = (props: Props) => {
    const {data} = props;
    const {patientInfo, histories, mpid, setLastUpdated} = React.useContext(QuestionaryContext);
    const [sending, setSending] = React.useState<boolean>(false);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    let questionaryInfo = histories.find((item: any) => item.ResponseSessionId === props.responseSessionId);

    async function submitIcdStatus(iClinicResponse: string){
        try {
            const requestInput = {
                mpid: mpid,
                responseSessionId: props.responseSessionId,
                action: "UpdateICDHistory",
                iClinicResponse: iClinicResponse
            }
            await axiosHttp.post('/Questionary/Participant',requestInput).then((res) => {
                console.log(res);
            }).catch((err) => {
                console.log(err)
            });
        } catch (error) {
            //handle error here...
            console.log(error)
        }
        setSending(false);
        setLastUpdated(new Date());
        //refresh

    }

    function sendICD() {
        const message = {
            action: 'addICD',
            data: {
                icdCodes: questionaryInfo?.IcdCodes,
                icdNames: questionaryInfo?.IcdNames
            }
        };
        console.log(message);
        const json = JSON.stringify(message);
        window.parent.postMessage(json, "*");
        setSending(true);
    }

    const handleAccordionChange = () => {
        setExpanded(!expanded)
    }
    const getNeedsString = ()=>{
        return data.hrsnSections.map((section: any)=>section.sectionName).join(', ')??'N/A'
    }
    const getZCodesString = ()=>{
        let string = '';
        let icdCodes = questionaryInfo?.IcdCodes.split('$');
        let icdNames = questionaryInfo?.IcdNames.split('$');
        for(let i = 0; i < icdCodes?.length; i++){
            if(icdCodes[i] && icdNames[i]) string += icdCodes[i] + '  ' + icdNames[i] + ', ';
        }
        string = string.length>0?string.trim().slice(0, -1):'N/A'
        return string;
    }

    React.useEffect(() => {
        //clean previous session
        window.addEventListener(
            'message',
            (event) => {
                // Do we trust the sender of this message?  (might be
                // different from what we originally opened, for example).
                try {
                    const json = JSON.parse(event.data);
                    if(json.data){
                        submitIcdStatus(json.data);
                    }
                } catch (e: any) {
                    // console.log(e);
                }
            },
            false
        );
    }, [])
    return (
        <Box>
            <Box minHeight={40} padding='16px 15.5px 0 15.5px'>
                <Typography variant="h3">{data?.questionaryDeepNested?.QuestionaryName}</Typography>
            </Box>
            <Divider/>
            <Box padding='16px 15.5px 24px 15.5px'>
                <Button
                    className={'no-print'}
                    onClick={() => {
                        window.print()
                    }}
                    variant="contained"
                    sx={{textTransform: 'none', borderRadius: '8px'}}>Print</Button>
                {storage.getItem('permission', undefined) === 'All' && questionaryInfo?.IcdCodes?
                <Button
                    disabled={questionaryInfo?.Status==='Completed' || questionaryInfo?.Status==='CompletedWithRetry' || sending}
                    onClick={() => {
                        sendICD()
                    }}
                    className={'no-print'}
                    variant="contained"
                    sx={{textTransform: 'none', borderRadius: '8px', marginLeft: '8px'}}>{(questionaryInfo?.Status==='Completed' || questionaryInfo?.Status==='CompletedWithRetry')?<><DoneIcon style={{color: '#656565', fontSize: 24, paddingRight: '4px', marginLeft: '-8px'}}/><span style={{color: '#656565'}}>Z-Codes Added to Current Visit</span></>:"Add ICD Z-Codes to iClinic"}</Button>: null}
                <Box paddingTop='24px' gap={2} display={'flex'}>
                    <Typography variant="h4">Visit Date: <span
                        style={{fontWeight: 400}}>{moment(questionaryInfo?.UpdateDateTime).format('MM/DD/YY')}</span></Typography>
                    <Typography variant="h4">Patient Name: <span
                        style={{fontWeight: 400}}>{patientInfo?.name}</span></Typography>
                    <Typography variant="h4">DOB: <span style={{fontWeight: 400}}>{patientInfo?.dob}</span></Typography>
                    <Typography variant="h4">Administered By: <span
                        style={{fontWeight: 400}}>{questionaryInfo?.AdministeredBy || "Unknown"}</span></Typography>
                </Box>
                <Box paddingTop='24px'>
                    {<CustomAccordion
                        open={expanded}
                        onClick={handleAccordionChange}
                        title={'Identified Needs & Recommended Z-Codes'}
                        children={<><Typography variant={'h4'}>
                            Identified Needs: <span style={{fontWeight: 400}}>{getNeedsString()}</span>
                        </Typography>
                            <Typography variant={'h4'}>
                                Recommended Z-Codes: <span style={{fontWeight: 400}}>{getZCodesString()}</span>
                            </Typography></>}/>}</Box>
            </Box>
            <Divider/>
        </Box>
    )

}