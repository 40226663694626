import React from 'react';
import {Box, Typography, List, ListItem, Chip, Divider, CircularProgress} from "@mui/material";
import DoneIcon from '@mui/icons-material/Done';
import ErrorIcon from '@mui/icons-material/Error';
import axiosHttp from "../../utils/axios";
import {QuestionaryContext} from "../../context/QuestionaryContext";

var moment = require('moment');

export const SocialRiskSummaryView = () => {
    const [data, setData] = React.useState({} as any);
    const [loading, setLoading] = React.useState(true)
    const {mpid, lastUpdated} = React.useContext(QuestionaryContext);
    React.useEffect(() => {
        setLoading(true)
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant', {
                    "mpid": mpid,
                    "action": "RiskSummary"
                })
                setData(response?.data?.data);
                setLoading(false);
            } catch (e) {
                console.log(e)
            }
        }
        if (mpid) {
            getData();
        }
    }, [mpid, lastUpdated])
    const getChip = (type: string) => {
        let status = data?.Risks?.find((risk: any) => risk.RiskName === type)?.RiskStatus;
        switch (status) {
            case 'At Risk':
                return <Chip
                    label={'At Risk'}
                    icon={<ErrorIcon
                        style={{color: '#C05314', fontSize: 20, paddingLeft: '3px', paddingRight: '2px'}}/>}
                    sx={{
                        borderRadius: '6px',
                        background: '#FCF3E5',
                        color: '#C05314',
                        fontWeight: 600,
                        fontSize: 12,
                        height: '24px'
                    }}/>;
            case 'Not at Risk':
                return <Chip
                    label={'Not at Risk'}
                    icon={<DoneIcon style={{color: '#008479', fontSize: 20, paddingLeft: '3px', paddingRight: '2px'}}/>}
                    sx={{
                        borderRadius: '6px',
                        background: '#E8F6F2',
                        color: '#008479',
                        fontWeight: 600,
                        fontSize: 12,
                        height: '24px'
                    }}/>
            default:
                return <Chip label={'Not Assessed'} sx={{
                    borderRadius: '6px',
                    background: '#EFEFEF',
                    color: '#888888',
                    fontWeight: 600,
                    fontSize: 12,
                    height: '24px'
                }}/>
        }
    }
    const getDateString = () => {
        if (data?.LastUpdateTime) {
            return moment(data?.LastUpdateTime).format('MM/DD/YYYY')
        }
        return 'N/A'
    }
    return (
        loading ?
            <Box padding={'16px 16px 24px 16px'} display={'flex'} justifyContent={'center'}><CircularProgress/></Box> :
            <Box padding={'16px 16px 24px 16px'}>
                <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'}>
                    <Typography variant="h3">Social Risk Summary</Typography>
                    <Typography variant="h3">Status</Typography>
                </Box>
                <Typography sx={{
                    fontSize: 12,
                    fontWeight: 700,
                    lineHeight: '16px',
                    color: '#0C77D8',
                }} paddingTop={'12px'} paddingBottom={'12px'}>Last Updated on: {getDateString()}</Typography>
                <Divider/>
                <List>
                    <ListItem alignItems={'center'} sx={{
                        justifyContent: 'space-between',
                        paddingTop: 0,
                        paddingLeft: 0,
                        paddingRight: 0
                    }}><Typography variant="subtitle2">Food</Typography>{getChip('Food')}</ListItem>
                    <Divider/>
                    <ListItem alignItems={'center'}
                              sx={{justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0}}><Typography
                        variant="subtitle2">Housing</Typography>{getChip('Housing')}</ListItem>
                    <Divider/>
                    <ListItem alignItems={'center'}
                              sx={{justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0}}><Typography
                        variant="subtitle2">Personal Safety</Typography>{getChip('Safety')}</ListItem>
                    <Divider/>
                    <ListItem alignItems={'center'}
                              sx={{justifyContent: 'space-between', paddingLeft: 0, paddingRight: 0}}><Typography
                        variant="subtitle2">Transportation</Typography>{getChip('Transportation')}</ListItem>
                    <Divider/>
                </List>
            </Box>)
}