import React from 'react';
import {Box, Typography, Button, Divider} from "@mui/material";
import {useQuestionaryContext} from "../../../../context/QuestionaryContext";
import CustomConfirmDialog from "./CustomConfirmDialog";
import axiosHttp from "../../../../utils/axios";

interface QuestionHeaderProps{
    data: any;
    loading: boolean;
    setLoading: (input: boolean)=>void;
    setClickedCheck: (input: boolean)=>void;
}
export const QuestionHeader = (props: QuestionHeaderProps) => {
    const {data, setLoading} = props
    const {mpid, info, initializeAnswerForm, copyFromLastScreening, questionaryId, histories} = useQuestionaryContext();
    const [onClickCopy, setOnClickCopy] = React.useState(false);
    const [onClickClear, setOnClickClear] = React.useState(false);
    const [lastQuestionary, setLastQuestionary] = React.useState<any>(undefined)
    const [lastQuestionaryDetail, setLastQuestionaryDetail] = React.useState<any>(undefined)

    React.useEffect(()=>{
        if(questionaryId && histories?.length>0){
            const lastQuestionaries = histories.filter((q: any)=>q?.Questionary?.QuestionaryId===questionaryId);
            if(lastQuestionaries?.length>0){
                setLastQuestionary(lastQuestionaries[0])
            }

        }
    },[questionaryId, histories])
    React.useEffect(()=>{
        if(lastQuestionary?.ResponseSessionId){
            const getData = async () => {
                try {
                    const response = await axiosHttp.post('/Questionary/Participant', {
                        "mpid": mpid,
                        "responseSessionId": lastQuestionary?.ResponseSessionId,
                        "action": "SessionAnswers",
                    })
                    setLastQuestionaryDetail(response?.data?.data)
                    setLoading(false)
                } catch (error) {
                    console.log(error)
                }
            };
            getData();
        }
    },[lastQuestionary])

    function onHandleCopy(){
        copyFromLastScreening(data, lastQuestionaryDetail.answers);
        setLoading(true);
        setOnClickCopy(false);
        setTimeout(()=>{setLoading(false)},500);
        props.setClickedCheck(false);
    }
    function onHandleClear(){
        initializeAnswerForm(data, true);
        setLoading(true);
        setOnClickClear(false);
        setTimeout(()=>{setLoading(false)},500);
        props.setClickedCheck(false);
    }

    return (
        <Box>
            <CustomConfirmDialog
                title={'Copy from Last Screening'}
                content={'Do you want to copy responses from the last session? This will overwrite any current responses.'}
                open={onClickCopy}
                onClose={() => setOnClickCopy(false)}
                onConfirm={() => onHandleCopy()}
            />
            <CustomConfirmDialog
                title={'Clear Responses'}
                content={`Are you sure you want to clear all responses?\nThis action cannot be undone.`}
                open={onClickClear}
                onClose={() => setOnClickClear(false)}
                onConfirm={() => onHandleClear()}
            />
            <Box height={40} padding='16px 15.5px 0 15.5px'>
                <Typography variant="h3">{info?.QuestionaryName}</Typography>
            </Box>
            <Divider/>
            <Box padding='16px 15.5px 24px 15.5px' gap={2} display={'flex'}>
                <Button
                    onClick={() => {
                        setOnClickCopy(true)
                    }}
                    disabled={!lastQuestionary}
                    variant="contained"
                    sx={{textTransform: 'none', borderRadius: '8px'}}>Copy from Last Screening</Button>
                <Button
                    onClick={() => {
                        setOnClickClear(true)
                    }}
                    variant="contained"
                    sx={{textTransform: 'none', borderRadius: '8px'}}>Clear Responses</Button>
            </Box>
            <Box padding='0 15.5px 0 15.5px'><Divider/></Box>
        </Box>
    )
}