import axios, {AxiosHeaders, AxiosRequestConfig, AxiosRequestHeaders} from "axios";
import { config } from "../config/config";
import {storage} from "./storage";

interface AdaptAxiosRequestConfig extends AxiosRequestConfig {
    headers: AxiosRequestHeaders
}

const axiosHttp = axios.create({
    baseURL: `${config.baseURL}`,

});

// Interceptors
axiosHttp.interceptors.request.use(
    (config): AdaptAxiosRequestConfig => {
        const accessToken = storage.getItem("AccessToken", undefined);
        // (config.headers as AxiosHeaders).set("Access-Control-Allow-Origin", '*');
        // (config.headers as AxiosHeaders).set("Access-Control-Allow-Headers", 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token');
        // (config.headers as AxiosHeaders).set('Access-Control-Allow-Credentials', true);
        (config.headers as AxiosHeaders).set('Content-Type', 'application/json');
        if(accessToken){
            (config.headers as AxiosHeaders).set("Authorization", `Bearer ${accessToken}`);
        }
        return config;
    },

    (error): any => {
        return Promise.reject(error);
    }
);

// {
//     "isSuccess": false,
//     "failReason": "ValidationException: Invalid KeyConditionExpression: An expression attribute value used in expression is not defined; attribute value: :sk",
//     "data": []
// }

axiosHttp.interceptors.response.use(
    async (response): Promise<any> => {
        if(response.data.isSuccess === false){
            const failReason = response?.data?.failReason;
            if(failReason){
                if(failReason.includes('ValidationException: Invalid KeyConditionExpression') || failReason.includes('AuthToken not registered with mpid')){
                    alert('Session expired or wrong token, please login again.')
                    storage.clearAll();
                    window.location.href = "/home";
                }
                else if(failReason==="Missing Authorization in headers"){
                    alert('Please login')
                    storage.clearAll();
                    window.location.href = "/home";
                }
                else {
                    console.log(failReason);
                   // window.location.reload();
                }
            }

        }
        return response;
    },
    async (error): Promise<any> => {
        return Promise.reject(error);
    }
);
export default axiosHttp;