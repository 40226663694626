import React from 'react';
import {AppBar, Box, Toolbar} from "@mui/material";
import logo from "../images/logo.svg";
import socialCare from "../images/socialCare.svg";

export const NewHeader = () => {
    return (
        <Box width={'100%'}>
            <AppBar position="static" sx={{backgroundColor: '#FFFFFF', height: 72}}><Toolbar>
                <div className="beyondHealthAppLogoContainer">
                    <div
                        data-testid="beyondHealthAppLogo"
                        id={'beyondHealthAppLogo'} className={'beyondHealthAppLogo'}
                        style={{backgroundImage: 'url(' + socialCare + ')'}}/>
                </div>
            </Toolbar></AppBar>
        </Box>
    )
}