import CryptoJS from 'crypto-js';

export const encryptData = (data: any) =>
    CryptoJS.AES.encrypt(data,  process.env.REACT_APP_SALT??'').toString();


export const decryptData = (ciphertext: any) => {
    const bytes = CryptoJS.AES.decrypt(ciphertext,  process.env.REACT_APP_SALT??'');
    try {
        return bytes.toString(CryptoJS.enc.Utf8);
    }catch(err){
        return null;
    }
}