import React from 'react';
import {QuestionaryContext} from "../../context/QuestionaryContext";
import {Box, CircularProgress, Typography} from "@mui/material";
import UnFinishTable from "./components/unFinishTable";
import axiosHttp from "../../utils/axios";
import {useNavigate} from "react-router-dom";

export const ViewAllUnfinish = () => {
    const {mpid, setQuestionaryId, setQuestionaryPeriodId} = React.useContext(QuestionaryContext);
    const [data, setData] = React.useState({} as any)
    const [loading, setLoading] = React.useState(true)
    const navigate = useNavigate();
    const handleOnClick = (questionary: any) => {
        setQuestionaryId(questionary.QuestionaryId);
        setQuestionaryPeriodId(questionary.QuestionaryPeriodId);
        navigate('getOne');
    }

    const handleBackMenu = () => {
        navigate('/menu');
    }

    React.useEffect(() => {
        const getData = async () => {
            try {
                const response = await axiosHttp.post('/Questionary/Participant',
                    {
                        "mpid": mpid,
                        "action":"Unfinished"})
                setData(response?.data?.data)
                setLoading(false);
                return response;

            } catch (error) {
                //handle error here...
                console.log(error)
            }

        }
        getData()
    }, [])
    return (
        <Box component="section"
             display="flex"
             alignItems="center"
             flexDirection={'column'}
             height={'100%'}>
                <Typography variant="h4" mt={3} mb={2}>Select HRSN Screening</Typography>
                <Box p={4}>
                    {loading?<CircularProgress/>
                        :
                    <UnFinishTable
                        rows={data ?? []}
                        onClick={handleOnClick}
                        handleBack={handleBackMenu}/>
                    }
                </Box>
            </Box>
    )
}